import cns from 'classnames'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'

import AnalysisInput from '@components/analysisInput/analysisInput'

import styles from './analysis.module.scss'

export default function Analysis({ pageId }) {
  return (
    <div className={styles.analysis}>
      <div
        className={cns(
          styles.analysis__wrapper,
          `analysis--wrapper`,
          `wrapper--extended`
        )}
      >
        <div className={styles.analysis__content}>
          <div className={styles.analysis__box}>
            <h2 className={styles.analysis__title}>
              <FormattedMessage id='analysis.title' />
            </h2>
            <p className={styles.analysis__text}>
              <FormattedMessage id='analysis.description' />
            </p>
            <div className={styles.input}>
              <AnalysisInput pageId={pageId} />
            </div>
          </div>

          <img
            className={styles.image}
            src={`/images/analysis-brands.png`}
            alt="analysis-brands"
            loading="lazy"
          />
        </div>
        <p className={styles.socialsHint}>
          <FormattedMessage id='analysis.availableSocials' />
        </p>
      </div>
    </div>
  )
}

Analysis.propTypes = {
  pageId: PropTypes.string,
}