import { useEffect, useState, useRef } from 'react'

const YM_KEYS = {
  'livedune.com': 24119476,
  'postingdune.ru': 97816014,
  'testdune.ru': 97786300
}

const TIME_TO_TRY = 300
const TIME_TO_STOP_TRY = 3000

const useVarioqub = () => {
  const [flags, setFlags] = useState({})
  const intervalRef = useRef(null)
  const timeoutRef = useRef(null)

  useEffect(() => {
    if (typeof window === 'undefined') return

    const ymID = YM_KEYS[window.location.host]

    timeoutRef.current = setTimeout(() => {
      if (intervalRef.current) clearInterval(intervalRef.current)
    }, TIME_TO_STOP_TRY)

    intervalRef.current = setInterval(() => {
      if (window.ymab) {
        window.ymab(`metrika.${ymID}`, 'getFlags', (yaFlags) => {
          const parsedFlags = Object.fromEntries(
            Object.entries(yaFlags).map(([key, value]) => [key, value[0]])
          )
          setFlags(parsedFlags)
        })
        clearInterval(intervalRef.current)
      }
    }, TIME_TO_TRY)

    return () => {
      if (intervalRef.current) clearInterval(intervalRef.current)
      if (timeoutRef.current) clearTimeout(timeoutRef.current)
    }
  }, [])

  return flags
}

export default useVarioqub
