import { useEffect, useState } from 'react'
import { useRouter } from 'next/router'
import dynamic from 'next/dynamic'

import { servicesDataHome } from '@data/servicesData'
import { advantagesListHome } from '@data/advantagesData'
import { reviewsDataHome } from '@data/reviewsData'
import { heroDataHome } from '@data/heroData'
import { brandsDataHome } from '@data/brandsData'

import useFormatMessage from '@utils/useFormatMessage'
import { getDefaultCurrency } from '@utils/Helper'
import useVarioqub from '@utils/useVarioqub'
import { $amplitudeIdentifySetOnce } from '@utils/amplitude'

import Reviews from '@components/reviews/reviews'
import Header from '@components/layouts/header/header'
import HeadBasic from '@components/basic/headBasic'
import Footer from '@components/layouts/footer/footer'
import Hero from '@components/hero/hero'
import Services from '@components/services/services'
import Cta from '@components/cta/cta'
import Section from '@components/layouts/section/section'
import Advantages from '@components/advantages/advantages'
import PricingSwitchers from '@components/pricingSwitchers/pricingSwitchers'
import Analysis from '@components/analysis/analysis'
import PricingPlans from '@components/pricingPlans/pricingPlans'

const HeroNew = dynamic(() => import('@components/heroNew/heroNew'), { ssr: false });

const pageId = 'home'

export default function Home() {
  const t = useFormatMessage()
  const { locale } = useRouter()

  const [selectedPeriod, setSelectedPeriod] = useState('year')
  const [selectedCurrency, setSelectedCurrency] = useState(
    getDefaultCurrency(locale)
  )

  useEffect(() => {
    setSelectedCurrency(getDefaultCurrency(locale))
  }, [locale])

  const flags = useVarioqub()
  const isSegmentExperiment = flags.expSegment === 'segment'

  useEffect(() => {
    if(flags.expSegment) {
      $amplitudeIdentifySetOnce('Landing Segmentation Experiment', flags.expSegment === 'segment')
    }
  }, [flags.expSegment])

  return (
    <>
      <HeadBasic
        title={t(`titles.${pageId}`)}
        description={t(`descriptions.${pageId}`)}
      />

      <Header pageId={pageId} />

      <Section>
      <Hero
        isSegmentExperiment={isSegmentExperiment}
        pageId={pageId}
        data={heroDataHome}
        brands={brandsDataHome}
      />
      </Section>

      {locale === 'ru' && (
        <Section className={`section--analysis`}>
          <Analysis pageId={pageId} />
        </Section>
      )}
      <Section className={`home__section--features`}>
        <Services pageId={pageId} data={servicesDataHome} />
      </Section>
      <Section className={`home__section--advantages section--dark`}>
        <Advantages pageId={pageId} data={advantagesListHome} />
      </Section>
      <Section>
        <HeroNew
          title={t('hero.titles.pricing')}
          text={t(`hero.descriptions.pricing`)}
          pageId={pageId}
        >
          <PricingSwitchers
            selectedPeriod={selectedPeriod}
            selectedCurrency={selectedCurrency}
            setSelectedPeriod={setSelectedPeriod}
            setSelectedCurrency={setSelectedCurrency}
          />
        </HeroNew>
      </Section>
      <Section>
        <PricingPlans
          selectedPeriod={selectedPeriod}
          selectedCurrency={selectedCurrency}
          pageId={pageId}
        />
      </Section>
      <Section>
        <Reviews data={reviewsDataHome} />
      </Section>
      <Section className={`section--cta`}>
        <Cta isSegmentExperiment={isSegmentExperiment} pageId={pageId} />
      </Section>
      <Footer pageId={pageId} />
    </>
  )
}
